export default [
  {
    name: 'Matt',
    quote: `This is the second time I've worked with Ahmed! He's extremely friendly and knowledgeable with chatbots.
    I'm sure I will work with him again next project I have
    `
  },
  {
    name: 'Lori',
    quote: `I had a phenomenal experience working with Ahmed! He is very hardworking & understood the project scope quickly. He is knowledgeable in chatbots, especially within Chatfuel. Fantastic job! I will be working with him again very soon`,
  },
  {
    name: 'Vince',
    quote: `It was great working with Ahmad, he always do the work as expected. If you need help in chatbots, Ahmad is the go-to engineer. We will work with him in future.`,
  },
  {
    name: 'Zuzana',
    quote: `Ahmed is excellent with communication and bot building.miztio Ive been terrible with my communication in following up and he comes through astking me questions on the layout i would like etc. Bot building is an art with flo, optimization etc. And i can honestly say I trust Ahmeds judgement
    `,
  },
  {
    name: 'P.P.',
    quote: `Thank you for the great work you did. Very technical and loves to solve challenges.
    `,
  },
  
]
